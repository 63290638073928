@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Zen Kaku Gothic New', sans-serif;
}

h1,
h2,
h3 {
    font-family: 'Zen Kaku Gothic New', sans-serif;
}

p {
    font-family: 'Zen Kaku Gothic New', sans-serif;
}


@font-face {
    font-family: 'Zen Kaku Gothic New';
    src: url('./assets/fonts/Zen_Kaku_Gothic_New/ZenKakuGothicNew-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Zen Kaku Gothic New';
    src: url('./assets/fonts/Zen_Kaku_Gothic_New/ZenKakuGothicNew-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}